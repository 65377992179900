import { Image } from "react-grid-gallery";
export const leaf   = require('./leaf.jpeg');
export const crochetImage = require('./leaf.jpeg');
export const silhoutte =     require('./leaf.jpeg')


export const images = [
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2588.jpg",
     "alt":"crochetpics/coasters/IMG_2588.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2588.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2589.jpg",
     "alt":"crochetpics/coasters/IMG_2589.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2589.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2590.jpg",
     "alt":"crochetpics/coasters/IMG_2590.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2590.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2591.jpg",
     "alt":"crochetpics/coasters/IMG_2591.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2591.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2596.jpg",
     "alt":"crochetpics/coasters/IMG_2596.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2596.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2597.jpg",
     "alt":"crochetpics/coasters/IMG_2597.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2597.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2598.jpg",
     "alt":"crochetpics/coasters/IMG_2598.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2598.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2599.jpg",
     "alt":"crochetpics/coasters/IMG_2599.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2599.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2600.jpg",
     "alt":"crochetpics/coasters/IMG_2600.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2600.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2601.jpg",
     "alt":"crochetpics/coasters/IMG_2601.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2601.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2602.jpg",
     "alt":"crochetpics/coasters/IMG_2602.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2602.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2605.jpg",
     "alt":"crochetpics/coasters/IMG_2605.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2605.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2606.jpg",
     "alt":"crochetpics/coasters/IMG_2606.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2606.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2607.jpg",
     "alt":"crochetpics/coasters/IMG_2607.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2607.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2608.jpg",
     "alt":"crochetpics/coasters/IMG_2608.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2608.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2609.jpg",
     "alt":"crochetpics/coasters/IMG_2609.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2609.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2610.jpg",
     "alt":"crochetpics/coasters/IMG_2610.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2610.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2611.jpg",
     "alt":"crochetpics/coasters/IMG_2611.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2611.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2612.jpg",
     "alt":"crochetpics/coasters/IMG_2612.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2612.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2613.jpg",
     "alt":"crochetpics/coasters/IMG_2613.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2613.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2614.jpg",
     "alt":"crochetpics/coasters/IMG_2614.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2614.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2615.jpg",
     "alt":"crochetpics/coasters/IMG_2615.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2615.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2618.jpg",
     "alt":"crochetpics/coasters/IMG_2618.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2618.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2620.jpg",
     "alt":"crochetpics/coasters/IMG_2620.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2620.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2622.jpg",
     "alt":"crochetpics/coasters/IMG_2622.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2622.jpg",
     "caption":"coaster"
  },
  {
     "src":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2666.jpg",
     "alt":"crochetpics/coasters/IMG_2666.jpg",
     "width":"320",
     "height":"174",
     "thumbnail":"https://francobucket1.s3.amazonaws.com/crochetpics/coasters/IMG_2666.jpg",
     "caption":"coaster"
  }
];