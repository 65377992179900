import React, {Component }  from 'react';
import {leaf, run} from '../../Assets/coasterimages';
import Gallery from 'react-grid-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import axios from 'axios';
import './CarDecor.css'

//const images = [{ src: {leaf} }, { src: {leaf} }];


class CarDecor extends Component {

    state = {
        image_array : []
    }

    
    loadData () {
        axios.get('https://ld40lca5hc.execute-api.us-east-1.amazonaws.com/Prod/crochetcardecor')
        .then( response => {
            console.log('response from coasters bucket data ---------------------', response.data);
            const myJSONData = JSON.parse(response.data.body)
            console.log('converted from coasters bucket data ---------------------');
            this.setState({image_array : myJSONData})
        
        })
        .catch(error => {
            console.log(error);
            console.log('some error')
        })

    }
    componentDidMount () {
       
        this.loadData();
    }

    render() {    
        return (
            <div className="cardecor" onContextMenu={(e)=> e.preventDefault()}>
               
                 <div className="galleryContainer cardecor">
                 <Gallery images={this.state.image_array} thumbnailStyle={this.styleSmall}/>                       
                 </div>
  
            </div>
        );
    }

    styleSmall(){
        return ({
           border:'5px solid #FAC9B8',
           width: '175px',
           height: '100%',

        });
     }
}

export default CarDecor;