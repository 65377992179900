import React from 'react';
import { Link } from 'react-router-dom';
import './Categories.css';

class Categories extends React.Component {
    render() {
        return (
            <div className="categories-container">
                <div className="row">
                    <div className="col-lg-3 parent">
                        <img src="https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/IMG_2608.jpg" alt="abstract" className="image"></img>
                            <Link to="/abstract" style={{ textDecoration: 'none' }} className="link">
                                <div className="centered">                  
                                    <span>coasters</span>
                                </div>
                            </Link>
                    </div>
                    <div className="col-lg-3 parent">
                        <img src="https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/bags/IMG_2658.jpg" alt="bags" className="image"></img>
                        <Link to="/bags" style={{ textDecoration: 'none' }} className="link">
                            <div className="centered">
                                <span>Bags and pouches</span>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-3 parent">
                        <img src="https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/winter/IMG_2629.jpg" alt="winter" className="image"></img>
                            <Link to="winter" style={{ textDecoration: 'none' }} className="link">
                                <div className="centered">                               
                                    <span>Winter Accessories</span>               
                                </div>
                            </Link>
                    </div>

                    <div className="col-lg-3 parent">
                            <img src="https://francobucket1.s3.amazonaws.com/crochetpics/cardecor/IMG_2788.jpg" alt="winter" className="image"></img>
                                <Link to="cardecor" style={{ textDecoration: 'none' }} className="link">
                                    <div className="centered">                               
                                        <span>Car Decor</span>               
                                    </div>
                                </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Categories;