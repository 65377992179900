import React, {Component} from 'react';
import './About.css'
import {crochetImage} from '../../Assets/coasterimages';

class About extends Component {

    render () {
        return (
            <div className="about-page">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-6 myimage">
                            <img src="https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/IMG_2666.jpg" className="coverimage" alt="crochetImage"></img>
                        </div>
                        <div class="col-lg-6 intro">
                            <h4 id="about">Crochet Center</h4>
                            <div className="introduction">                                
                                
                                <br></br>
                            
                                <p>
                                 Experience the world of Crochet
                                </p>

                                <p>Have a nice day!</p>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;