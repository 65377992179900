import React, {Component} from 'react';
import Gallery from 'react-grid-gallery';
import axios from 'axios';
import './Scenic.css'

class Scenic extends Component {

    state = {
        image_array : []
    }

    componentDidMount () {
       
        this.loadData();
    }

    loadData () {

    }

    styleSmall(){
        return ({
           
           width: '100%',
           height: '100%',
           backgroundColor: 'none',
      
           
           
        });
     }


    render() {
        return (
            <div className="scenic">
               
            <div className="galleryContainer">
            <Gallery images={this.state.image_array} 
                     enableImageSelection={false} 
                     backdropClosesModal={true} 
                     margin={10}
                     rowHeight={220}
                     showLightboxThumbnails={true}
                     thumbnailStyle={this.styleSmall}/>
            </div>
         

            
       </div>
        );
    }
}

export default Scenic;