import React, {Component} from 'react';
import NavBar from '../Navbar/Navbar';
import './Layout.css';
import StickyFooter from 'react-sticky-footer';



class Layout extends Component {


    render () {
        return (
            <div>
              <NavBar> </NavBar>
              
              
            


            </div>
        )
    }
}

export default Layout;